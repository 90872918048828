import {quote as quoteApi} from 'central-endpoints'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {QuoteNavigation, context, reactHooks} from 'quote-ui-lib'
import React, {useContext, useRef} from 'react'
import {useTranslation} from 'react-i18next'

const {
  useApiErrorHandler,
  useNotificationMsgs,
  useQueryParams,
  useQueryState,
  useTestHooks
} = reactHooks

const propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

const queryParamsSchema = {
  fileId: {
    type: 'int',
    default: 0
  }
}

const DownloadPage = ({history, location}) => {
  const {queryParams} = useQueryParams({
    history,
    location,
    queryParamsSchema
  })
  const {childHook, dataHook} = useTestHooks('page')
  const {t} = useTranslation()
  const {addMessage, dismissMessage, messages} = useNotificationMsgs()

  useApiErrorHandler([quoteApi])

  const queryParamRef = useRef()
  queryParamRef.current = queryParams

  const coreData = useContext(context.CoreDataContext)
  const {categories, quoteUser} = coreData.state

  const [fileData] = useQueryState({
    defaultValue: {},
    query: async () => {
      const data = await quoteApi.files.get(queryParamRef.current.fileId)
      window.open(data.downloadLink)
      return data
    },
    dependencies: [queryParams.fileId]
  })

  return (
    <>
      <QuoteNavigation
        categories={categories}
        className='pos-abs w-100p'
        hook={dataHook}
        t={t}
        transientMessages={messages}
        user={quoteUser}
        onAddMessage={addMessage}
        onDismissMessage={dismissMessage}
      />
      {!isEmpty(fileData) &&
        <div className='h-100vh font-l flex jc-center ai-center'>
          <span data-hook={childHook('download-file')}>
            {t('downloadFile', {fileName: fileData.name})}
          </span>
        </div>
      }
    </>
  )
}

DownloadPage.propTypes = propTypes

export default DownloadPage
