import {hookUtils} from 'ciena-js-utils'
import PropTypes from 'prop-types'
import React from 'react'
import {Component, addClassName} from 'react-frost'

const {getDataHook, getHookHelpers} = hookUtils

export const cardPropTypes = {
  ...Component.PropTypes,

  /** The name of the color for this card, will be used to build bg-{color} and color-{color} classNames */
  color: PropTypes.string.isRequired,

  /** The className to add on the content (underneath the color bar) */
  contentClassName: PropTypes.string,

  /** URL to link to (if this Card itself is a link) */
  href: PropTypes.string.isRequired
}

const propTypes = {
  ...cardPropTypes,

  children: PropTypes.node
}

function Card (props) {
  const {
    children,
    className,
    color,
    contentClassName,
    hook,
    hookPrefix,
    href
  } = props

  const {childHook} = getHookHelpers(hook, hookPrefix)
  const dataHook = getDataHook(hook)

  const extraClassNames = `bg-white bxs-0-2-4-black-40 flex fd-col color-${color} cur-pointer td-none`.split(' ')

  const fullClassName = addClassName(className, extraClassNames.join(' '))
  const colorBarClassName = `h-5 bg-${color}`

  return (
    <a
      className={fullClassName}
      data-hook={dataHook}
      href={href}
      rel="noopener noreferrer"
      target='_blank'
    >
      <div
        className={colorBarClassName}
        data-hook={childHook('colorBar')}
      />
      <div
        className={addClassName(contentClassName, 'flex-grow-1')}
        data-hook={childHook('content')}
      >
        {children}
      </div>
    </a>
  )
}

Card.propTypes = propTypes

export default Card
