/* global WEBPACK_DEFINE_SENTRY_DSN */
import {History, Window} from 'ciena-js-utils'
import 'normalize.css' // eslint-disable-line
import 'frost.css' // eslint-disable-line
import {ErrorBoundary, SpaRouting, context} from 'quote-ui-lib'
import {CentralSentry} from 'quote-ui-lib/utils'
import React from 'react'
import ReactDOM from 'react-dom'
import {I18nextProvider, useTranslation} from 'react-i18next'
import {HashRouter, Route, Switch} from 'react-router-dom'

import i18n from './i18n'
import Dashboard, {LegacyDashboard} from 'src/components/dashboard'
import DownloadPage from 'src/components/download-page'

const {CoreDataProvider, NotificationsProvider} = context

const environment = process.env.NODE_ENV
const {withQueryParamSupport} = SpaRouting

CentralSentry({environment}).init({
  attachStacktrace: true,
  dsn: WEBPACK_DEFINE_SENTRY_DSN
})

// TODO: Swap out LegacyApp with App once e2e is fixed
// eslint-disable-next-line no-unused-vars
function App () {
  const {t} = useTranslation()
  const renderDashboardPage = withQueryParamSupport(Dashboard, {t, hook: 'page'})
  return (
    <HashRouter>
      <Switch>
        <Route
          component={renderDashboardPage}
          exact
          path='/'
        />
        <Route
          component={DownloadPage}
          exact
          path='/download'
        />
      </Switch>
    </HashRouter>
  )
}

const windowInstance = new Window()
const history = new History(windowInstance)
function LegacyApp () {
  const {t} = useTranslation()
  return <LegacyDashboard history={history} hook='page' t={t} />
}

ReactDOM.render(
  <ErrorBoundary environment={environment}>
    <I18nextProvider i18n={i18n}>
      <CoreDataProvider>
        <NotificationsProvider>
          <LegacyApp />
        </NotificationsProvider>
      </CoreDataProvider>
    </I18nextProvider>
  </ErrorBoundary>,
  document.getElementById('app')
)
