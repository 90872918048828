import {Window} from 'ciena-js-utils'
import {find} from 'lodash'
import PropTypes from 'prop-types'
import {CoreData, QuoteNavigation, SpaRouting} from 'quote-ui-lib'
import React from 'react'
import {Page} from 'react-frost'

import SimpleCard from 'src/components/simple-card'

const plannerColor = 'blue-1'
const quoteColor = 'green-1'

const {SpaPage} = SpaRouting

export const windowInstance = new Window()

const DashboardMixin = (PageClass) => class extends PageClass {
  static propTypes = {
    history: PropTypes.any,

    i18n: PropTypes.shape({
      labels: PropTypes.shape({
        about: PropTypes.string.isRequired,
        bidPlus: PropTypes.string.isRequired,
        channelPlanner: PropTypes.string.isRequired,
        configTool: PropTypes.string.isRequired,
        logout: PropTypes.string.isRequired,
        openDetails: PropTypes.string.isRequired,
        plannerPlus: PropTypes.string.isRequired,
        quotePlus: PropTypes.string.isRequired,
        sqc: PropTypes.string.isRequired
      }).isRequired
    })
  }

  static defaultProps = {
    i18n: {
      labels: {
        about: 'About',
        bidPlus: 'BidPlus',
        channelPlanner: 'Channel Planner',
        configTool: 'Config Tool',
        logout: 'Logout',
        mercator: 'Mercator',
        openDetails: 'Open details',
        plannerPlus: 'PlannerPlus',
        quotePlus: 'QuotePlus',
        sqc: 'SQC'
      }
    }
  }

  state = {
    ...this.state,
    ...CoreData.initialState
  }

  coreData = new CoreData({page: this})

  componentDidMount () {
    this.coreData.fetchAll()
  }

  /**
   * Use user permission to determine if user has access to BPK
   * @returns {Boolean} true if user has access
  */
  hasConfigTool () {
    const {quoteUser} = this.state
    const found = find(this.state.categories, {name: 'Config Tool'})
    return quoteUser.permissions.includes('config-tool:bpk-support') && found
  }

  /**
   * Use user permission to determine if user has access to Planner tools
   * @returns {Boolean} true if user has access
   */
  hasPlanner () {
    const {quoteUser} = this.state
    return quoteUser.permissions.includes('config-tool:planner-support')
  }

  /**
   * Use categories returned by /nav-items to determine if user has access to Quote app
   * @returns {Boolean} true if user has access
  */
  hasQuote () {
    const found = find(this.state.categories, {name: 'QuotePlus'})
    return Boolean(found)
  }

  /**
   * Use categories returned by /nav-items to determine if user has access to Bid app
   * @returns {Boolean} true if user has access
  */
  hasBid () {
    const found = find(this.state.categories, {name: 'BidPlus'})
    return Boolean(found)
  }

  /**
   * Use categories returned by /nav-items to determine if user has access to SQC app
   * @returns {Boolean} true if user has access
  */
  hasSqc () {
    const found = find(this.state.categories, {name: 'SQC'})
    return Boolean(found)
  }

  maybeRenderBid () {
    if (!this.hasBid()) {
      return null
    }

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props
    const {href} = find(this.state.categories, {name: 'BidPlus'})

    return (
      <SimpleCard
        color={quoteColor}
        hook={childHook('card', {name: 'bidPlus'})}
        href={href}
        iconUrl='/bid-ui/react/icons/nav-bid-plus.svg#root'
        title={i18n.labels.bidPlus}
      />
    )
  }

  maybeRenderChannelPlanner () {
    if (!this.hasPlanner()) {
      return null
    }

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props
    const href = this.getAppsPath('channel-map-ui')

    return (
      <SimpleCard
        color={plannerColor}
        hook={childHook('card', {name: 'channelMap'})}
        href={href}
        iconUrl='/konstruktor-ui/icons/nav-channel-types.svg#root'
        title={i18n.labels.channelPlanner}
      />
    )
  }

  maybeRenderConfigTool () {
    if (!this.hasConfigTool()) {
      return null
    }

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props
    const {href} = find(this.state.categories, {name: 'Config Tool'})

    return (
      <SimpleCard
        color={plannerColor}
        hook={childHook('card', {name: 'configTool'})}
        href={href}
        iconUrl='/konstruktor-ui/icons/nav-config-tool.svg#root'
        title={i18n.labels.configTool}
      />
    )
  }

  getAppsPath (path) {
    const host = windowInstance.window.location.host
    const supportedHostsPrefix = ['cicd.']
    const prefix = supportedHostsPrefix.find(name => host.includes(name)) || ''
    const href = `https://${prefix}apps.ciena.com/${path}/`

    return href
  }

  maybeRenderPlannerPlus () {
    if (!this.hasPlanner()) {
      return null
    }

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props
    const href = this.getAppsPath('planner-plus-ui')

    return (
      <SimpleCard
        color={plannerColor}
        hook={childHook('card', {name: 'plannerPlus'})}
        href={href}
        iconUrl='/konstruktor-ui/icons/nav-topology.svg#root'
        title={i18n.labels.plannerPlus}
      />
    )
  }

  renderMercator () {
    // There is no special permissions for Mercator

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props

    return (
      <SimpleCard
        color={plannerColor}
        hook={childHook('card', {name: 'mercator'})}
        href={'/subseamap-ui/#/'}
        iconUrl='/subseamap-ui/icons/nav-mercator.svg#root'
        title={i18n.labels.mercator}
      />
    )
  }

  maybeRenderQuote () {
    if (!this.hasQuote()) {
      return null
    }

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props
    const {groups: [quotes]} = find(this.state.categories, {name: 'QuotePlus'})

    return (
      <SimpleCard
        color={quoteColor}
        hook={childHook('card', {name: 'quotePlus'})}
        href={quotes.links[0].href}
        iconUrl='/quote-ui-quote/icons/nav-quote-plus.svg#root'
        title={i18n.labels.quotePlus}
      />
    )
  }

  maybeRenderSqc () {
    if (!this.hasSqc()) {
      return null
    }

    const {childHook} = this.getHookHelpers()
    const {i18n} = this.props
    const {href} = find(this.state.categories, {name: 'SQC'})

    return (
      <SimpleCard
        color={quoteColor}
        hook={childHook('card', {name: 'sqc'})}
        href={href}
        iconUrl='/quote-ui-quote/icons/nav-quote.svg#root'
        title={i18n.labels.sqc}
      />
    )
  }

  renderNavBar () {
    const {childHook} = this.getHookHelpers()
    const {categories, messages, quoteUser} = this.state
    const {t} = this.props

    return (
      <QuoteNavigation
        categories={categories}
        className='pos-abs w-100p'
        hook={childHook('quoteNav')}
        t={t}
        transientMessages={messages}
        user={quoteUser}

        onAddMessage={this.addMessage}
        onDismissMessage={this.dismissMessage}
      />
    )
  }

  render () {
    const {childHook} = this.getHookHelpers()
    const dataHook = this.getDataHook()

    const navigationJsx = this.renderNavBar()
    const mercatorJsx = this.renderMercator()
    const configToolJsx = this.maybeRenderConfigTool()
    const plannerPlusJsx = this.maybeRenderPlannerPlus()
    const channelMapJsx = this.maybeRenderChannelPlanner()
    const quoteJsx = this.maybeRenderQuote()
    const sqcJsx = this.maybeRenderSqc()
    const bidJsx = this.maybeRenderBid()

    return (
      <div className='pos-rel flex h-100p bg-lgrey-3' data-hook={dataHook}>
        {navigationJsx}
        <div
          className='flex-1 p-10 m-t-50'
          data-hook={childHook('dashboardWrapper')}
        >
          <div
            className='grid grid-gap-10 grid-cols-auto-fit-240 jc-center'
            data-hook={childHook('cardWrapper')}
          >
            {mercatorJsx}
            {plannerPlusJsx}
            {configToolJsx}
            {channelMapJsx}
            {quoteJsx}
            {sqcJsx}
            {bidJsx}
          </div>
        </div>
      </div>
    )
  }
}

export default class Dashboard extends DashboardMixin(SpaPage) {}

export class LegacyDashboard extends DashboardMixin(Page) {}
