import i18n from 'i18next'
import {locales} from 'quote-ui-lib'
import {initReactI18next} from 'react-i18next'

import enPage from './locales/en/page.json'

export const translations = {
  'en': {
    'page': {...locales.en, ...enPage}
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,

    defaultNS: 'page',
    resources: translations
  })

export default i18n
