import {hookUtils} from 'ciena-js-utils'
import PropTypes from 'prop-types'
import React from 'react'
import {addClassName} from 'react-frost'

import Card, {cardPropTypes} from 'src/components/card'

const {getHookHelpers} = hookUtils

const propTypes = {
  ...cardPropTypes,

  iconType: PropTypes.oneOf(['svg', 'image']),

  /** URL for an svg icon that will be <use>'d inside an <svg> */
  iconUrl: PropTypes.string.isRequired,

  /** String display title for the card */
  title: PropTypes.string.isRequired
}

const defaultProps = {
  iconType: 'svg'
}

function SimpleCard (props) {
  const {
    className,
    color,
    hook,
    hookPrefix,
    href,
    iconType,
    iconUrl,
    title
  } = props

  const {childHook} = getHookHelpers(hook, hookPrefix)

  const renderImage = () => {
    if (iconType !== 'image') {
      return
    }
    return (
      <img
        className='h-60 w-auto'
        data-hook={childHook('image')}
        src={iconUrl}
      />
    )
  }

  const renderSvg = () => {
    if (iconType !== 'svg') {
      return
    }
    return (
      <svg
        className='size-60 fill-current-color'
        data-hook={childHook('icon')}
      >
        <use xlinkHref={iconUrl} />
      </svg>
    )
  }

  const imageJsx = renderImage()
  const svgJsx = renderSvg()

  return (
    <Card
      className={addClassName(className, 'w-240 h-190')}
      color={color}
      contentClassName='flex fd-col ai-center jc-center m-t--5'
      hook={hook}
      hookPrefix={hookPrefix}
      href={href}
    >
      {imageJsx}
      {svgJsx}
      <div
        className='font-l p-t-10'
        data-hook={childHook('title')}
      >
        {title}
      </div>
    </Card>
  )
}

SimpleCard.propTypes = propTypes
SimpleCard.defaultProps = defaultProps

export default SimpleCard
